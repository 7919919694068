import Header from "./header/Header";
import Nav from "./nav/Nav";
import About from "./about/About";
import Experience from "./experience/Experience";
import Services from "./services/Services";
import Portfolio from "./portfolio/Portfolio";
import Testimonial from "./testimonial/Testimonial";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";
import Loading from "./loader/Loading";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const MainIndex = () => {
  const [loading, setLoading] = useState(true);
  // let navigate = useNavigate();

  setTimeout(() => {
    setLoading(false);
  }, 5000);

  useEffect(() => {
    const Sections = document.querySelectorAll("section");
    const Navs = document.querySelectorAll("nav a");
    window.addEventListener("scroll", () => {
      if (window.scrollY <= 15) {
        document?.getElementById("nav_home").click();
      }
    });
    const observer = new IntersectionObserver(
      (items) => {
        items.forEach((item) => {
          if (item.isIntersecting) {
            let targetID = item.target.id;
            Navs.forEach((nav) => {
              if (nav.id.replace("nav_", "") === targetID) {
                document?.getElementById(nav.id).click();
              }
            });
            // let url = `${window.location.origin}/#${item.target.id}`;
            // console.log(url);

            // console.log(item.target.id);
            // navigate(`/#${item.target.id}`);
            // window.location.href = url
            // window.location.pathname = `/#${item.target.id}`
          }
        });
      }
      // { threshold: 0.3 }
    );

    Sections.forEach((sect) => {
      observer.observe(sect);
    });
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? <Loading /> : null}
      <Header />
      <Nav />
      <About />
      <Experience />
      <Services />
      <Portfolio />
      <Testimonial />
      <Contact />
      <Footer />
    </>
  );
};
export default MainIndex;
