import React from "react";
import "./about.css";
import ME from "../../assets/me.jpeg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
const About = () => {
  return (
    <>
      <section id="about">
        <h5>Get To Know</h5>
        <h2>About Me</h2>

        <div className="container about__container">
          <div className="about__me">
            <div className="about__me__image">
              <img src={ME} alt="me" />
            </div>
          </div>

          <div className="about__content">
            <div className="about__cards">
              <article data-aos="fade-right" className="about__card">
                <FaAward className="about__icon" />
                <h5>Experience</h5>
                <small>4+ Years</small>
              </article>
              <article data-aos="fade-up" className="about__card">
                <FiUsers className="about__icon" />
                <h5>Clients</h5>
                <small>30+ Worldwide</small>
              </article>
              <article data-aos="fade-left" className="about__card">
                <VscFolderLibrary className="about__icon" />
                <h5>Projects</h5>
                <small>150+ Completed</small>
              </article>
            </div>

            <p>
            As a passionate teenage coder, I have cultivated a strong foundation in web development, encompassing both frontend and backend domains. My toolkit comprises React.js for frontend development, Express.js for backend operations, and MongoDB for database management. I'm dedicated to honing my skills and delivering professional-quality work in the world of software development.
            </p>
            <a data-aos="fade-up" href="#contact" className="btn btn-primary">
              Let's Talk
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
