import "./header.css";
import me_one from "../../assets/me-1.png";
import Typewriter from "typewriter-effect/dist/core";
import { useEffect } from "react";
const Header = () => {
  useEffect(() => {
    new Typewriter("#typewriter", {
      strings: ["Developer", "Programmer"],
      autoStart: true,
      loop: true,
      pauseFor: 1000,
      delay: 40,
      deleteSpeed: 40,
    });
  }, []);
  return (
    <header id="home">
      <div className="container header___container">
        <div className="hero flex items_center justify_between">
          <div
            data-aos="fade-right"
            className="left flex_one flex justify_center"
          >
            <img src={me_one} alt="me-2" />
          </div>
          <div className="right flex_one">
            <h6 data-aos="fade-down">Tarikul</h6>

            <h1>
              I'm a <span id="typewriter"></span>
            </h1>
            <p>
              I develop websites with{" "}
              <a
                href="https://www.google.com/search?q=MERN&oq=MERN&aqs=chrome..69i57.2273j0j1&sourceid=chrome&ie=UTF-8"
                target="_blank"
                rel="noreferrer"
              >
                MERN
              </a>{" "}
            </p>
            <div>
              <a
                data-aos="fade-up"
                href="#about"
                className="btn btn_secondary about_me_btn"
              >
                About ME
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
