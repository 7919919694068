const data = {
  social_links: {
    facebook: "https://www.facebook.com/tarikull.dev/",
    github: "https://github.com/faketi101",
    pinterest: "https://www.pinterest.com/tarikul.dev/",
    instagram: "https://instagram.com/tarikul.dev/",
    twitter: "https://twitter.com/faketi101",
    coffee: "buymeacoffee.com/tarikul/",
    linkedIn: "https://www.linkedin.com/in/its-tarikul-islam/",
  },
  service_data: [
    {
      id: 1,
      name: "Html",
      img: "html.png",
      percent: "98%",
      background: "#ff6600",
      aos_data: "fade-right",
    },
    {
      id: 2,
      name: "CSS",
      img: "css.png",
      percent: "98%",
      background: "#3A9BD5",
      aos_data: "fade-right",
    },
    {
      id: 3,
      name: "JavaScript",
      img: "js.jpg",
      percent: "95%",
      background: "#ECDD23",
      aos_data: "fade-right",
    },
    {
      id: 4,
      name: "Express JS",
      img: "expressjs.png",
      percent: "94%",
      background: "#ECDD23",
      aos_data: "fade-left",
    },
    {
      id: 5,
      name: "React JS",
      img: "reactjs.jpg",
      percent: "90%",
      background: "#87DAFE",
      aos_data: "fade-left",
    },
    {
      id: 6,
      name: "Mongo DB",
      img: "mongodb.png",
      percent: "95%",
      background: "#4D9444",
      aos_data: "fade-left",
    },
  ],
  work_data: [
    {
      id: 1,
      name: "Class Attendance Management System",
      type: "Web Development",
      img: "atn-1.jpg",
      aos_data: "fade-down",
      link: "https://ncpsc.faketi.me",
      git: "https://ncpsc.faketi.me",
    },
    {
      id: 2,
      name: "Discord Bot For Call of Duty Warzone",
      type: "Discord Bot Development",
      img: "ethan.jpg",
      aos_data: "fade-up",
      link: "https://github.com/faketi101/wz-discord-bot",
      git: "https://github.com/faketi101/wz-discord-bot",
    },
    {
      id: 3,
      name: "Brigade Landpage Design Project",
      type: "Web Development",
      img: "brigate-1.jpg",
      aos_data: "fade-right",
      link: "https://server.faketi.xyz/work/brigate",
      git: "https://github.com/faketi101/brigate-site",
    },
    {
      id: 4,
      name: "Call of Duty Bangladesh Site",
      type: "Web Development",
      img: "codbd.png",
      aos_data: "fade-right",
      link: "https://callofdutybd.com",
      git: "https://callofdutybd.com",
    },
    {
      id: 5,
      name: "New Tab Interface for Chrome and Brave",
      type: "Extension Development",
      img: "ext-1.jpg",
      aos_data: "fade-up",
      link: "https://github.com/faketi101/newtabextension",
      git: "https://github.com/faketi101/newtabextension",
    },
    {
      id: 6,
      name: "Site for Reco Bot",
      type: "Website Development",
      img: "reco_site.jpg",
      aos_data: "fade-up",
      link: "https://recobot.in/",
      git: "https://recobot.in/",
    },
    {
      id: 7,
      name: "Site for THE APEX BATTALION ESPORTS",
      type: "Website Development",
      img: "apex.jpg",
      aos_data: "fade-up",
      link: "https://theapexbattalion.com/",
      git: "https://theapexbattalion.com/",
    },
    {
      id: 7,
      name: "Site for REZZY",
      type: "Website Development",
      img: "rezzy_designs.com.jpg",
      aos_data: "fade-up",
      link: "https://rezzydesigns.com/",
      git: "https://rezzydesigns.com/",
    },
    {
      id: 7,
      name: "New Tab Extension 2.0",
      type: "Extension Development",
      img: "new_tab_ext2.0.jpg",
      aos_data: "fade-up",
      link: "https://github.com/faketi101/new_tab_extension_2.0",
      git: "https://github.com/faketi101/new_tab_extension_2.0",
    },
    {
      id: 7,
      name: "Site for AGILE RECRUITMENT AGENCY",
      type: "Website Development",
      img: "agilerecruitmentagency.com.jpg",
      aos_data: "fade-up",
      link: "https://agilerecruitmentagency.com/",
      git: "https://agilerecruitmentagency.com/",
    },
    {
      id: 8,
      name: "Full authentication with Dashboard",
      type: "NodeJS Development",
      img: "login_dashboard.png",
      aos_data: "fade-up",
      link: "https://github.com/faketi101/login_auth_dashboard",
      git: "https://github.com/faketi101/login_auth_dashboard",
    },
  ],
  testimonials: [
    {
      name: "Tanvir Ahmed",
      post: "Founder",
      ins: "The Apex Battalion",
      ins_link: "https://apexbattalion.tarikul.dev/",
      img: "adm_tanvir_ahmed.jpg",
      text: "A guy with many talents. Tarikul aka Snowden is the best example of a hard-working and a smart working individual. He always meets the deadline. His work ethics has allowed us to achieve some unachievable milestones in a short period of time. I would 100% recommend him to anyone who's looking for web designer/expert. Mark my word, he will deliver your work the way you want it to be delivered.",
    },
    {
      name: "Arikuzzaman",
      post: "Founder",
      ins: "Task Force Mission 141",
      ins_link: "https://discord.gg/Y3JTffsN8X",
      img: "arik.png",
      text: "Snowden aka Tamim is the Coding king. He has such a talent at a very young age. He developed 3 very successful bots with regular updates. If you thought that was it than you are absolutely wrong. He is also a Web Designer and Full stack Developer. I would definitely recommend this talent to anyone searching for an Expert coder or Web designer. You wouldn't regret it. Wishing him the best.",
    },
    {
      name: "Sujon Hasan",
      post: "CEO",
      ins: "Promise Web IT",
      ins_link: "https://promisewebit.com/",
      img: "sujon.png",
      text: "Tarikul, also known as Snowden, is a remarkable individual with a unique blend of creativity and professionalism. His ability to balance innovation with practicality makes him stand out in the field. Tarikul’s attention to detail and commitment to meeting deadlines has consistently driven our projects to success, often exceeding expectations. His exceptional work ethic and expertise in web design make him a valuable asset to any team. If you’re looking for someone who will deliver high-quality work tailored to your needs, look no further—Tarikul is your guy!",
    },
  ],
  experiences: {
    frontend: [
      {
        name: "HTML",
        ex: "Experienced",
      },
      {
        name: "CSS",
        ex: "Intermediate",
      },
      {
        name: "JavaScript",
        ex: "Experienced",
      },
      {
        name: "Bootstrap",
        ex: "Experienced",
      },
      {
        name: "TailWind",
        ex: "Experienced",
      },
      {
        name: "React",
        ex: "Experienced",
      },
    ],
    backend: [
      {
        name: "Express",
        ex: "Experienced",
      },
      {
        name: "MongoDB",
        ex: "Experienced",
      },
      {
        name: "JavaScript",
        ex: "Experienced",
      },
      {
        name: "Python",
        ex: "Experienced",
      },
      {
        name: "MySQL",
        ex: "Basic",
      },
    ],
  },
  services: [
    {
      name: "Web App Development",
      li: [],
      aos_data: "fade-up",
    },
    {
      name: "Node js Management",
      li: [],
      aos_data: "fade-down",
    },
    {
      name: "UI/UX Design",
      li: [],
      aos_data: "fade-right",
    },
    {
      name: "Web Development",
      li: [],
      aos_data: "fade-up",
    },
    {
      name: "Discord Bot Development",
      li: [],
      aos_data: "fade-left",
    },
    {
      name: "Wordpress Management",
      li: [],
      aos_data: "fade-left",
    },
  ],
};

export default data;
