import React from "react";
import "./testimonial.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";

import "swiper/css/pagination";
import data from "../../config/dataConfig";
const Testimonial = () => {
  const single = (d, i) => {
    return (
      <>
        <SwiperSlide key={`_gd${i + 1}`} className="tesitmonial">
          <div className="client__avatar">
            <img src={`img/${d.img}`} alt="av" />
          </div>
          <h5 className="client__name">{d.name}</h5>
          <small className="client__post">
            {d.post.toUpperCase()} OF{" "}
            <a href={d.ins_link} target="_blank" rel="noreferrer">
              {d.ins.toUpperCase()}
            </a>{" "}
          </small>

          <small className="client__review">{d.text}</small>
        </SwiperSlide>
      </>
    );
  };
  return (
    <>
      <section id="testimonials">
        <h5>Review From Top Clients</h5>
        <h2>Testimonials</h2>

        <Swiper
          className="container testimonials__container"
          modules={[Pagination, Autoplay]}
          spaceBetween={40}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
        >
          {data.testimonials.map(single)}
        </Swiper>
      </section>
    </>
  );
};

export default Testimonial;
