import "./pageNotFound.css";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <>
      <div className="page_not_found">
        <h1 className="text_not_found">Opps!</h1>
        <h1 className="text_not_found">404 Page Not Found.</h1>
        <Link className="back_to_home" to="/">Back to Home</Link>
      </div>
    </>
  );
};

export default PageNotFound;
