import React, { useState } from "react";
import "./nav.css";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";
import {FiMonitor} from "react-icons/fi"
const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <>
      <nav>
        <a
          title="Home"
          onClick={() => setActiveNav("#")}
          className={activeNav === "#" ? "active" : null}
          href="#home"
          id="nav_home"
        >
          <AiOutlineHome />
        </a>
        <a
          onClick={() => setActiveNav("#about")}
          className={activeNav === "#about" ? "active" : null}
          href="#about"
          title="About"
          id="nav_about"
        >
          <AiOutlineUser />
        </a>
        <a
          title="Experience"
          onClick={() => setActiveNav("#experience")}
          className={activeNav === "#experience" ? "active" : null}
          href="#experience"
          id="nav_experience"
        >
          <BiBook />
        </a>
        <a
          title="Services"
          onClick={() => setActiveNav("#services")}
          className={activeNav === "#services" ? "active" : null}
          href="#services"
          id="nav_services"
        >
          <RiServiceLine />
        </a>
        <a
          title="Portfolio"
          onClick={() => setActiveNav("#portfolio")}
          className={activeNav === "#portfolio" ? "active" : null}
          href="#portfolio"
          id="nav_portfolio"
        >
          <FiMonitor />
        </a>
        <a
          title="Contact"
          onClick={() => setActiveNav("#contact")}
          className={activeNav === "#contact" ? "active" : null}
          href="#contact"
          id="nav_contact"
        >
          <BiMessageSquareDetail />
        </a>
      </nav>
    </>
  );
};

export default Nav;
