import React from "react";
import "./experience.css";
import { AiFillCheckCircle } from "react-icons/ai";
import data from "../../config/dataConfig";
const Experience = () => {
  const f_single = (d, i) => {
    return (
      <>
        <article
          data-aos="fade-right"
          data-aos-delay={(i + 1) * 70}
          key={`__d${i + 1}`}

          className="experience__details"
        >
          <AiFillCheckCircle className="experience__details_icon" />
          <div>
            <h4>{d.name}</h4>
            <small className="text-light">{d.ex}</small>
          </div>
        </article>
      </>
    );
  };
  const b_single = (d, i) => {
    return (
      <>
        <article
          data-aos="fade-left"
          data-aos-delay={(i + 1) * 70}
          className="experience__details"
        >
          <AiFillCheckCircle className="experience__details_icon" />
          <div>
            <h4>{d.name}</h4>
            <small className="text-light">{d.ex}</small>
          </div>
        </article>
      </>
    );
  };
  return (
    <>
      <section id="experience">
        <h5>What Skills I Have</h5>
        <h2>My Experience</h2>

        <div className="container experience__container">
          <div className="experience__frontend">
            <h3>Frontend Development</h3>
            <div className="experience__content">
              {data.experiences.frontend.reverse().map(f_single)}
            </div>
          </div>
          <div className="experience__backend">
            <h3>Backend Development</h3>
            <div className="experience__content">
              {data.experiences.backend.map(b_single)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Experience;
