import "./index.css";

import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import MainIndex from "./components/MainIndex";
import PageNotFound from "./components/404/PageNotFound";
import axios from "axios";
import { useEffect } from "react";
import { server_url } from "./config/serverConfig";
AOS.init({
  duration: 500,
});
function App() {
  const getip = async () => {
    try {
      const ipurl = `${server_url}/api/sendip`;
      await axios.get(ipurl);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    // getip();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainIndex />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
