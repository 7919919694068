import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";
import data from "../../config/dataConfig";
const Services = () => {
  const single = (d, i) => {
    return (
      <>
        <article data-aos={d.aos_data} key={`_d${i + 1}`} className="service">
          <div className="service__head">
            <h3>{d.name}</h3>
          </div>
          <ul className="service__list">
            {d.li.map((i) => (
              <li>
                <BiCheck className="service__list__icon" />
                <p>{i}</p>
              </li>
            ))}
          </ul>
        </article>
      </>
    );
  };
  return (
    <>
      <section id="services">
        <h5>What I Offer</h5>
        <h2>Services</h2>

        <div className="container services__container">
          {data.services.map(single)}
        </div>
      </section>
    </>
  );
};

export default Services;
