import React, { useEffect } from "react";
import "./loading.css";
import Typed from "typewriter-effect/dist/core";
const Loading = () => {
  useEffect(() => {
    let options = {
      strings: [
        "printf(hello world!)",
        "console.log(loading...)",
        "fmt.print(loading...)",
        "print(hello world!)",
      ],
      pauseFor: 100,
      delay: 40,
      deleteSpeed: 40,
      loop: true,
      autoStart: true,
    };
    new Typed(".__loader", options);
  }, []);
  return (
    <>
      <div class="__loader"></div>
    </>
  );
};

export default Loading;
