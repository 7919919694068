import React from "react";
import "./footer.css";
import { AiOutlineGithub } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FiTwitter } from "react-icons/fi";
import { BsPinterest } from "react-icons/bs";
import { SiBuymeacoffee } from "react-icons/si";
import { BsLinkedin } from "react-icons/bs";
import data from "../../config/dataConfig";
const Footer = () => {
  return (
    <>
      <footer>
        <a href="/" className="footer__logo">
          TARIKUL.DEV
        </a>

        <ul className="permalinks">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#experience">Experience</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>

        <div className="footer__socials">
          <a href={data.social_links.coffee}>
            <SiBuymeacoffee />
          </a>
          <a href={data.social_links.github}>
            <AiOutlineGithub />
          </a>
          <a href={data.social_links.linkedIn}>
            <BsLinkedin />
          </a>
          <a href={data.social_links.facebook}>
            <FaFacebook />
          </a>
          <a href={data.social_links.instagram}>
            <GrInstagram />
          </a>
          <a href={data.social_links.twitter}>
            <FiTwitter />
          </a>
          <a href={data.social_links.pinterest}>
            <BsPinterest />
          </a>
        </div>

        <div className="footer__copyright">
          <small>TARIKUL ISLAM. All Rights Reserved</small>
        </div>
      </footer>
    </>
  );
};

export default Footer;
