import React from "react";
import "./portfolio.css";
import { useEffect, useState } from "react";
import { server_url } from "../../config/serverConfig";
import axios from "axios";

const Single = (d, i) => {
  return (
    <>
      <article
        data-aos={d.aos_data}
        data-aso-delay={(i + 1) * 100}
        key={`___d${i + 1}`}
        className="portfolio__item"
      >
        <div className="portfolio__item__image">
          <img src={`${server_url}${d.img}`} alt="img" />
        </div>
        <h5>{d.work_type.toUpperCase()}</h5>
        <h3>{d.work_name.toUpperCase()}</h3>
        <div className="portfolio__item__cta">
          {d.demo_link ? (
            <a
              href={d.demo_link}
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Demo
            </a>
          ) : null}
          {d.github_link ? (
            <a
              href={d.github_link}
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
          ) : null}
          {d.buy_link ? (
            <a
              href={d.buy_link}
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Buy
            </a>
          ) : null}
        </div>
      </article>
    </>
  );
};
const Portfolio = () => {
  const [data, setData] = useState("");
  const getWorks = async () => {
    try {
      const url = `${server_url}/works/get`;
      let res = await axios.get(url);
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getWorks();
  }, []);
  return (
    <>
      <section id="portfolio">
        <h5>My Recent Works</h5>
        <h2>Portfolio</h2>

        <div className="container portfolio__container">
          {data && data.length !== 0 ? data.map(Single) : "No data found."}
        </div>
      </section>
    </>
  );
};

export default Portfolio;
