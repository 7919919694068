import React, { useState } from "react";
import axios from "axios";
import "./contact.css";
import { MdOutlineMail } from "react-icons/md";
import { RiMessengerLine, RiDiscordFill,RiLinkedinFill } from "react-icons/ri";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { server_url } from "../../config/serverConfig";

const Contact = () => {
  const ds = "<Snowden/>#0861";

  const [loading, setLoading] = useState(false);

  const [errInp, setErrInp] = useState("");
  const submitMail = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const from = e.target.email.value;
    const subject = e.target.subject.value;
    const message = e.target.message.value;
    const post_obj = {
      name,
      email: from,
      subject,
      message,
      _pa: "message?true?",
    };
    // console.log(post_obj);
    let link = `${server_url}/api/send_message`;
    let link2 = `${server_url}/sendmail`;

    const validation = () => {
      if (!name) {
        toast.error("Name input must be filled");
        setErrInp("name");
        return false;
      } else if (!from) {
        setErrInp("email");
        toast.error("Email input must be filled");
        return false;
      } else if (!subject) {
        setErrInp("subject");
        toast.error("Subject input must be filled");
        return false;
      } else if (!message) {
        setErrInp("message");
        toast.error("Message input must be filled");
        return false;
      } else {
        return true;
      }
    };
    // console.log(validation());
    try {
      if (validation()) {
        setLoading(true);
        toast.warning("Mail Sending...");
        let response = await axios.post(link, post_obj);

        setLoading(false);
        try {
          await axios.post(link2, post_obj);
        } catch (error) {
          console.log(error);
        }
        if (response.data.success) {
          toast.success("Mail has sent to TARIKUL");
          e.target.reset();
        }
      }
    } catch (error) {
      setLoading(false);
      // console.dir(error);
      toast.error(error?.response?.data?.error || "Something Went Wrong !");
    }
  };
  const copy = () => {
    navigator.clipboard.writeText(
      "https://discord.com/users/706749687289479222"
    );
    toast.success("Copied to Clipboard.");
  };
  return (
    <>
     
      <section id="contact">
        <h5>Get In Touch</h5>
        <h2>Contact Me</h2>
        {loading ? null : null}
        <div className="container contact__container">
          <div className="contact__options">
            <article
              data-aos="fade-right"
              data-aos-delay={50}
              className="contact__option"
            >
              <MdOutlineMail className="contact__option__icon" />
              <h4>Email</h4>
              <h5>hello@tarikul.dev</h5>
              <a href="mailto:hello@tarikul.dev">Send a Message</a>
            </article>


            <article
              data-aos="fade-right"
              data-aos-delay={50}
              className="contact__option"
            >
              <RiLinkedinFill className="contact__option__icon" />
              <h4>Linked In</h4>
              <h5>its-tarikul-islam</h5>
              <a href="https://www.linkedin.com/in/its-tarikul-islam/">Visit Profile</a>
            </article>


            <article
              title="Click to Copy"
              onClick={copy}
              data-aos="fade-right"
              data-aos-delay={110}
              className="contact__option pointer"
            >
              <RiDiscordFill className="contact__option__icon" />
              <h4>Discord</h4>
              <h5>{ds}</h5>
              <a href="https://discord.com/users/706749687289479222">
                Send a Message
              </a>
            </article>

            <article
              data-aos="fade-right"
              data-aos-delay={170}
              className="contact__option"
            >
              <RiMessengerLine className="contact__option__icon" />
              <h4>Messenger</h4>
              <h5>tarikull.dev</h5>
              <a href="https://m.me/tarikull.dev">Send a Message</a>
            </article>
          </div>

          <form autoComplete="off" data-aos="slide-right" onSubmit={submitMail}>
            <input
              className={errInp === "name" ? "invInput" : null}
              type="text"
              autoComplete="none"
              name="name"
              placeholder="Your Name"
            />
            <input
              className={errInp === "email" ? "invInput" : null}
              type="email"
              autoComplete="none"
              name="email"
              placeholder="Your Email"
            />
            <input
              className={errInp === "subject" ? "invInput" : null}
              type="text"
              autoComplete="none"
              name="subject"
              placeholder="Your Subject"
            />
            <textarea
              className={errInp === "message" ? "invInput" : null}
              name="message"
              cols={30}
              rows={10}
              placeholder="Your Message"
              defaultValue={""}
            />

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
